import React from "react";

const ContactUsHero = () => {
  return (
    <React.Fragment>
      <div className="contact-us">
        <div className="mask"></div>
        <div className="container mx-auto px-4 md:px-8 xl:px-20 flex flex-col justify-start gap-4 items-center md:items-start">
          <p className="text-dt-section-heading font-bold !leading-none text-dt-blue-light">
            Contact Us
          </p>
          <p className="text-dt-section-caption-lg font-semibold text-white !leading-none md:!text-start text-center">
            Hear Back Within 24 Hours!
          </p>
          <p className="text-dt-section-description !leading-normal mt-3 font-light text-white !text-center md:!text-start sm:w-[100%] md:w-[75%] lg:w-[50%] xl:w-[30%]">
            Are you unsure about trusting DevTrove with your project? Just leave
            a message, and we’ll get in touch to ensure your satisfaction.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUsHero;
