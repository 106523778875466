import React, { useEffect, useRef, useState } from "react";
import { SVGs } from "../../assets/svgs";
import { testemonials } from "../../utils/data";

const Slider = () => {
  const slideRef = useRef(null);
  const containerRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(2);
  const [isDragging, setIsDragging] = useState(false);
  const startPos = useRef(0);
  const currentPos = useRef(0);

  // Handle mouse/touch start event
  const handleDragStart = (event) => {
    event.preventDefault();
    setIsDragging(true);

    // Determine if it's a touch event or mouse event
    startPos.current =
      event.type === "touchstart" ? event.touches[0].clientX : event.clientX;

    // Disable default scroll behavior
    document.body.style.overflow = "hidden";
  };

  // Handle mouse/touch move event
  const handleDragMove = (event) => {
    if (!isDragging) return;

    // Determine current position
    currentPos.current =
      event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
  };

  // Handle mouse/touch end event
  const handleDragEnd = () => {
    if (!isDragging) return;

    setIsDragging(false);
    const movedBy = currentPos.current - startPos.current;

    if (movedBy > 50) {
      // Move to previous slide
      setCurrentSlide((prevSlide) =>
        prevSlide > 0 ? prevSlide - 1 : prevSlide
      );
    } else if (movedBy < -50) {
      // Move to next slide
      setCurrentSlide((prevSlide) =>
        prevSlide < testemonials.length - 1 ? prevSlide + 1 : prevSlide
      );
    }

    // Enable default scroll behavior
    document.body.style.overflow = "auto";
  };

  const handleScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.deltaY > 0) {
      setCurrentSlide((prevSlide) =>
        prevSlide < testemonials.length - 1 ? prevSlide + 1 : prevSlide
      );
    } else if (event.deltaY < 0) {
      setCurrentSlide((prevSlide) =>
        prevSlide > 0 ? prevSlide - 1 : prevSlide
      );
    }
  };

  useEffect(() => {
    const sliderContainer = containerRef.current;
    if (sliderContainer) {
      sliderContainer.addEventListener("wheel", handleScroll, {
        passive: false,
      });
    }

    // Cleanup event listener
    return () => {
      if (sliderContainer) {
        sliderContainer.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  const isCurrentIndex = (index) =>
    index === currentSlide ? "active" : "inactive";

  const horizontalData = {
    container: "flex md:flex-row flex-col items-center justify-center",
    controls:
      "flex md:flex-col md:mt-0 mt-4 md:translate-x-[50%] items-center justify-center controls gap-3",
  };

  const notHorizontalData = {
    container: "flex md:flex-row flex-col items-center justify-center",
    controls:
      "flex md:flex-col md:mt-0 mt-4 md:translate-x-[50%] items-center justify-center controls gap-3",
  };

  const [classes, setClasses] = useState(notHorizontalData);
  const [displacement, setDisplacement] = useState(25);

  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth < 675) {
        setClasses(horizontalData);
        setDisplacement(0);
      } else {
        setDisplacement(25);
        setClasses(notHorizontalData);
      }
    };

    updateCardsToShow();
    window.addEventListener("resize", updateCardsToShow);

    return () => window.removeEventListener("resize", updateCardsToShow);
  }, []);

  const getControlsOpacity = (index) => {
    return Math.abs(index - currentSlide) * 0.2;
  };

  return (
    <div
      className={classes.container}
      ref={containerRef}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      <div className="slider-container overflow-hidden h-[400px] sm:h-[454px] md:h-[516px]">
        {testemonials.map((review, index) => (
          <div
            key={index}
            style={{
              transform: `translate(${Math.abs(
                (index - currentSlide) * displacement
              )}%, ${(index - currentSlide) * 75 + 75}%)`,
              opacity: 1 - Math.abs((index - currentSlide) * 0.5),
              transition: "transform 0.5s ease, opacity 0.5s ease",
            }}
            className={`${isCurrentIndex(
              index
            )} slide flex flex-row items-start h-auto sm:h-[175px] md:h-[200px] justify-start gap-2 sm:gap-4 p-6 shadow-dt-testemonial-card rounded-dt-md bg-white w-[100%] sm:w-[90%] xl:w-[80%]`}
            ref={slideRef}
          >
            <div className="flex flex-col items-start justify-start w-[75%] gap-3">
              <p className="text-dt-section-description md:text-dt-section-description-lg lg:text-dt-section-description xl:text-dt-section-description-lg !leading-none text-dt-main-blue flex flex-row items-center justify-start font-semibold">
                {review.name}
              </p>
              <p className="text-dt-section-description-sm md:text-dt-section-description lg:text-dt-section-description-sm xl:text-dt-section-description !leading-tight text-dt-dark">
                {review.text}
              </p>
            </div>
            <SVGs.QuotesSVG />
          </div>
        ))}
      </div>
      <div className={classes.controls}>
        {testemonials.map((_, index) => (
          <div
            className="sm:w-[15px] w-[10px] sm:h-[15px] h-[10px] bg-dt-main-blue rounded-[50%] transition-opacity duration-200 ease-in cursor-pointer"
            onClick={() => setCurrentSlide(index)}
            style={{
              opacity: 1 - getControlsOpacity(index),
            }}
            key={index}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
