import { motion } from "framer-motion";
import React from "react";
import { testemonialsStats } from "../../utils/data";
import Slider from "../subComponents/Slider";

const Testemonials = ({ statsOnly, bgWhite }) => {
  return (
    <div
      id="testimonials"
      className={` ${bgWhite ? "bg-white" : "bg-dt-light"} relative`}
    >
      <div className="container mx-auto px-4 md:px-8 xl:px-20 pt-12 pb-20 flex flex-col items-center justify-center gap-8">
        {!statsOnly && (
          <div className="flex flex-col lg:flex-row items-center justify-start w-full xl:gap-0 gap-4">
            <div className="flex flex-col items-start justify-start gap-6 xl:w-[50%] lg:w-3/5 w-4/5">
              <p className="text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading font-bold !leading-none text-dt-main-blue text-center xl:self-start self-center">
                Testimonials
              </p>
              <p className="text-dt-section-description-sm md:text-dt-section-description text-dt-dark text-center xl:text-start">
                Discover how our premium services have earned the trust and
                praise of our satisfied clients and valued users.
              </p>
            </div>

            <div className="xl:w-[60%] w-full">
              <Slider />
            </div>
          </div>
        )}

        {/* Stats Section */}
        <div
          className={`bg-dt-dark mx-auto flex flex-row items-stretch justify-between flex-wrap md:flex-nowrap ${
            !statsOnly && "mt-12"
          } w-full xl:w-[80%] rounded-dt-xl`}
        >
          {testemonialsStats.map((stat, index) => (
            <React.Fragment key={index}>
              {/* Motion div for hover effect */}
              <motion.div
                className="flex flex-col items-center justify-center stat flex-grow md:flex-grow-0"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3, ease: "easeOut" }}
              >
                <p className="text-dt-section-heading lg:text-dt-section-heading-lg !leading-none font-bold text-white">
                  {stat.value}
                </p>
                <p className="text-dt-section-description-sm lg:text-dt-section-description font-bold text-white text-center">
                  {stat.name}
                </p>
              </motion.div>
              {index !== testemonialsStats.length - 1 && (
                <div className="divider"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testemonials;
