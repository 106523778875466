import React from "react";
import { useNavigate } from "react-router-dom";
import { portfolios } from "../../utils/data";

const PortfolioBody = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {portfolios.map((portfolio, index) => (
        <div
          className={`${
            index % 2 === 0 ? "bg-dt-section-light" : "bg-white"
          }  z-50 relative ${index === 0 && "rounded-t-[33px]"}`}
        >
          <div className="container mx-auto px-8 md:px-8 xl:px-20 py-12 lg:py-16 xl:py-24 flex flex-col items-start justify-start">
            <div
              className={`flex ${
                index % 2 === 0
                  ? "lg:flex-row flex-col"
                  : "lg:flex-row-reverse flex-col"
              } items-start gap-4 sm:gap-6 lg:gap-12`}
            >
              <div className="flex flex-col items-start justify-start gap-4">
                <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
                  {portfolio.name}
                </p>
                <p className="text-dt-dark text-justify text-[12px] md:text-dt-section-description-sm lg:text-dt-section-description-md xl:text-dt-section-description !leading-[1.35]">
                  {portfolio.desc}
                </p>
                <button
                  onClick={() => navigate(`/portfolio/${portfolio.name}`)}
                  className="bg-dt-main-light border-[2px] border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-light px-[20px] py-[4px] ;g:py-[6px] text-[12px] md:text-dt-section-description-sm lg:text-dt-nav-link text-white capitalize font-medium rounded-dt-sm"
                >
                  Read More
                </button>
              </div>
              <img
                alt="portfolio-ill"
                src={portfolio.ill}
                className="lg:h-[337px] p-2 shadow-dt-portfolio-card bg-white border-solid border-[1px] h-[300px] lg:w-auto w-full rounded-dt-md"
              />
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default PortfolioBody;
