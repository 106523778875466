import React from "react";
import { useNavigate } from "react-router-dom";
import CollapseLogo from "../../assets/pngs/header-logo.png";
import { SVGs } from "../../assets/svgs";

const NavbarCollapse = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleLinkClick = (link) => {
    onClose(false);
    navigate(link);
  };
  return (
    <div
      style={{
        transform: `${!isOpen ? "translateX(-100%)" : "translateX(0%)"} `,
      }}
      className="fixed z-50 left-0 top-0 w-[20%] transition-transform ease-out duration-300 min-w-[400px] h-full rounded-e-dt-lg bg-dt-dark flex flex-col items-start justify-start"
    >
      <div className="flex px-8 py-12 items-center justify-between w-full">
        <img
          onClick={() => handleLinkClick("/")}
          alt="logo"
          src={CollapseLogo}
          className="h-[46px] cursor-pointer"
        />
        <SVGs.CloseSVG
          className="h-[46px] w-[46px] cursor-pointer"
          onClick={() => onClose(false)}
        />
      </div>
      <div className="flex flex-col w-full items-start justify-start">
        <div
          onClick={() => handleLinkClick("/")}
          className="flex flex-row px-8 w-full py-3 group items-center justify-start gap-8 relative bg-transparent cursor-pointer hover:bg-dt-section-dark-hover transition-all ease-out"
        >
          <div className="absolute left-0 top-0 h-full w-0 z-50 transition-w ease-out group-hover:w-1 rounded-e-dt-sm bg-dt-blue-light"></div>
          <SVGs.HomeSVG className="h-[24px] w-[24px] group-hover:fill-white" />
          <p className="text-dt-light text-dt-section-description group-hover:fill-white">
            Home
          </p>
        </div>
        <div
          onClick={() => handleLinkClick("/services")}
          className="flex flex-row px-8 w-full py-3 items-center justify-start group gap-8 relative bg-transparent cursor-pointer hover:bg-dt-section-dark-hover transition-all ease-out"
        >
          <div className="absolute left-0 top-0 h-full w-0 z-50 transition-w ease-out group-hover:w-1 rounded-e-dt-sm bg-dt-blue-light"></div>
          <SVGs.ServicesSVG className="h-[24px] w-[24px]" />
          <p className="text-dt-light text-dt-section-description">Services</p>
        </div>
        <div
          onClick={() => handleLinkClick("/portfolio")}
          className="flex flex-row px-8 w-full py-3 items-center justify-start group gap-8 relative bg-transparent cursor-pointer hover:bg-dt-section-dark-hover transition-all ease-out"
        >
          <div className="absolute left-0 top-0 h-full w-0 z-50 transition-w ease-out group-hover:w-1 rounded-e-dt-sm bg-dt-blue-light"></div>
          <SVGs.PortfolioSVG className="h-[24px] w-[24px]" />
          <p className="text-dt-light text-dt-section-description">Portfolio</p>
        </div>
      </div>
      <button
        onClick={() => handleLinkClick("/contact-us")}
        className="bg-dt-main-light border-[2px] mt-auto mx-auto mb-24 border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-dark px-[20px] py-[6px] text-dt-nav-link text-white capitalize font-medium rounded-dt-sm"
      >
        contact us
      </button>
    </div>
  );
};

export default NavbarCollapse;
