import React from "react";
import ContactUsForm from "../subComponents/ContactUsForm";

const NewLetter = () => {
  return (
    <div className="relative">
      <ContactUsForm />
      <div
        className="w-full bg-dt-light"
        style={{ paddingTop: "var(--contact-form-half-height)" }}
      >
        <div className="container mx-auto px-8 xl:px-20 pt-4 pb-12 flex flex-col items-center justify-center gap-4">
          <p className="text-dt-main-blue leading-none font-bold text-center text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading">
            Subscribe to our Newsletter
          </p>
          <p className="text-dt-dark text-[12px] max-w-lg md:text-dt-section-description-sm lg:text-dt-section-description !leading-[1.35] text-center">
            Subscribe to our newsletter today to receive upcoming discounts,
            offers, industry trends, and DevTrove updates directly in your
            inbox.
          </p>
          <input
            type="email"
            placeholder="Enter your email..."
            className="py-2 px-4 rounded-dt-xl mt-1 w-4/5 md:w-3/5 lg:w-1/2 bg-white shadow-dt-input-card text-dt-section-description"
          ></input>
          <button className="bg-dt-main-light mt-1 border-[2px] border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-light px-[20px] py-[4px] ;g:py-[6px] text-[12px] md:text-dt-section-description-sm lg:text-dt-nav-link text-white capitalize font-medium rounded-dt-xl">
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLetter;
