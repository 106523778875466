import React from "react";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AppLayout from "../layout";
import ContactUsPage from "../pages/ContactUs";
import LandingPage from "../pages/Landing";
import PortfoliosPage from "../pages/Portfolios";
import PortfoliosSubPage from "../pages/Portfolios/SubPage";
import ServicesPage from "../pages/Services";
import ScrollToTop from "../utils/scroll";

const Routing = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<LandingPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/portfolio" element={<PortfoliosPage />} />
          <Route path="/portfolio/:portfolio" element={<PortfoliosSubPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;
