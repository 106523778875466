import React, { useEffect, useRef, useState } from "react";
import Hero from "../../components/Landing/Hero";
import Portfolio from "../../components/Landing/Portfolio";
import Services from "../../components/Landing/Services";
import Testemonials from "../../components/Landing/Testimonials";
import Footer from "../../components/Layout/Footer";

const LandingPage = () => {
  const heroRef = useRef(null);
  const [heroHeight, setHeroHeight] = useState(0);

  useEffect(() => {
    const updateHeroHeight = () => {
      if (heroRef.current) {
        setHeroHeight(heroRef.current.clientHeight);
      }
    };

    const observer = new ResizeObserver(() => {
      updateHeroHeight();
    });

    if (heroRef.current) {
      updateHeroHeight();
      observer.observe(heroRef.current);
    }

    window.addEventListener("resize", updateHeroHeight);

    return () => {
      window.removeEventListener("resize", updateHeroHeight);
      observer.disconnect();
    };
  }, []);

  return (
    <div className="z-20 relative">
      <div ref={heroRef} className="fixed overflow-clip w-full z-0">
        <Hero />
      </div>
      <div style={{ paddingTop: heroHeight - 36 }} className="z-50">
        <Services />
      </div>
      <Portfolio />
      <Testemonials />
      <Footer />
    </div>
  );
};

export default LandingPage;
