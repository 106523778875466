import React, { useEffect, useRef, useState } from "react";
import Testemonials from "../../components/Landing/Testimonials";
import Footer from "../../components/Layout/Footer";
import PortfolioBody from "../../components/Portfolios/Body";
import PortfolioHero from "../../components/Portfolios/Hero";

const PortfoliosPage = () => {
  const heroRef = useRef(null);
  const [heroHeight, setHeroHeight] = useState(0);

  useEffect(() => {
    const updateHeroHeight = () => {
      if (heroRef.current) {
        setHeroHeight(heroRef.current.clientHeight);
      }
    };

    const observer = new ResizeObserver(() => {
      updateHeroHeight();
    });

    if (heroRef.current) {
      updateHeroHeight();
      observer.observe(heroRef.current);
    }

    window.addEventListener("resize", updateHeroHeight);

    return () => {
      window.removeEventListener("resize", updateHeroHeight);
      observer.disconnect();
    };
  }, []);

  return (
    <div className="z-20 relative">
      <div
        ref={heroRef}
        className="fixed overflow-clip w-full z-0  bg-[#010914]"
      >
        <PortfolioHero />
      </div>
      <div
        style={{
          paddingTop: heroHeight - 36,
          transition: "padding-top 0.5s ease-in-out",
        }}
        className="z-50"
      >
        <PortfolioBody />
        <Testemonials bgWhite statsOnly />
      </div>
      <Footer />
    </div>
  );
};

export default PortfoliosPage;
