import React from "react";
import PortfolioHeroBG from "../../assets/pngs/portfolio-hero-bg.png";

const PortfolioHero = ({ sub }) => {
  return (
    <div className="container mx-auto px-4 md:px-8 xl:px-20 flex items-center justify-between z-10">
      <div className="absolute z-40 top-0 lg:right-[50%] md:right-[75%] right-[100%] translate-x-[100%] md:w-[85%] w-[125%] lg:w-[60%] h-full bg-dt-hero-mask"></div>
      <p className="text-dt-main-light py-64 z-50 text-dt-section-heading font-bold !leading-none">
        {sub || "Portfolio"}
      </p>
      <div className="lg:w-1/2 md:w-3/4 w-full h-full absolute right-0">
        <img
          alt="service-hero-bg"
          src={PortfolioHeroBG}
          className="object-cover object-right h-full w-full"
        />
      </div>
    </div>
  );
};

export default PortfolioHero;
