import React from "react";
import HeroBG from "../../assets/anims/Background animation website.mp4";
import HeroLogo from "../../assets/anims/ezgif.com-animated-gif-maker (1).gif";

const Hero = () => {
  return (
    <div className="container mx-auto px-4 md:px-8 xl:px-20">
      <video
        autoPlay
        loop
        muted
        ref={(videoRef) => {
          if (videoRef) {
            videoRef.playbackRate = 1.25;
          }
        }}
        className="absolute top-0 left-0 w-full h-full -z-10 object-cover"
      >
        <source src={HeroBG} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="flex flex-row pb-24 xl:pb-36 pt-32 xl:pt-48 pt items-center justify-between relative">
        <div className="w-4/5 mx-auto sm:text-start text-center sm:w-1/2">
          <p className="text-[36px] text-white md:text-dt-section-heading lg:text-dt-section-heading-lg xl:text-dt-max font-bold !leading-tight">
            <span className="text-dt-main-light">BRINGING</span> Your Ideas to
            Digital<span className="text-dt-main-light"> Life</span>
          </p>
          <p className="text-white mt-4 text-[12px] md:text-dt-section-description-sm lg:text-dt-section-description">
            At DevTrove, we turn visions into digital realities through
            innovative websites, modern apps, AI innovations, blockchain, and
            cloud solutions. Our technology takes your business to the next
            level.
          </p>
          <p></p>
        </div>
        <div className="sm:w-1/2 sm:opacity-100 opacity-50 w-4/5 flex items-center justify-center sm:justify-end left-[50%] sm:translate-x-0 sm:left-0 translate-x-[-50%] sm:relative absolute">
          <img alt="hero-logo" src={HeroLogo} className="lg:w-3/5 w-4/5" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
