import React, { useState } from "react";

import { Outlet } from "react-router-dom";
import Header from "../components/Layout/Header";
import NavbarCollapse from "../components/Layout/NavbarCollapse";

const AppLayout = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  console.log(isNavOpen);
  return (
    <div className="relative z-10 bg-dt-main">
      <Header openNav={setIsNavOpen} />
      <NavbarCollapse isOpen={isNavOpen} onClose={setIsNavOpen} />
      <Outlet />
    </div>
  );
};

export default AppLayout;
