import { ReactComponent as AccessManagementSVG } from "./access-management.svg";
import { ReactComponent as AiSVG } from "./ai.svg";
import { ReactComponent as AngularSVG } from "./angular.svg";
import { ReactComponent as ApacheSparkSVG } from "./apachespark.svg";
import { ReactComponent as ApiSVG } from "./api.svg";
import { ReactComponent as AppDevelopmentSVG } from "./app-development.svg";
import { ReactComponent as ArbitrumSVG } from "./arbitrum.svg";
import { ReactComponent as AutomationSVG } from "./automation.svg";
import { ReactComponent as AwsSVG } from "./aws.svg";
import { ReactComponent as BlockchainSVG } from "./blockchain.svg";
import { ReactComponent as BurpSuiteSVG } from "./burp-suite.svg";
import { ReactComponent as BussinessSVG } from "./business.svg";
import { ReactComponent as ChainlinkSVG } from "./chainlink.svg";
import { ReactComponent as ChatbotSVG } from "./chatbot.svg";
import { ReactComponent as ChatGPTSVG } from "./chatgpt.svg";
import { ReactComponent as CloseSVG } from "./close.svg";
import { ReactComponent as CloudISVG } from "./cloud-infrastructure.svg";
import { ReactComponent as ConversionSVG } from "./conversion.svg";
import { ReactComponent as CrmSVG } from "./crm.svg";
import { ReactComponent as CrossPlatformSVG } from "./cross-platform.svg";
import { ReactComponent as CustomSVG } from "./custom-icon.svg";
import { ReactComponent as CyberSVG } from "./cyber.svg";
import { ReactComponent as DappSVG } from "./dapp.svg";
import { ReactComponent as DataAiSVG } from "./dataAi.svg";
import { ReactComponent as DigitalMarketingSVG } from "./digital-marketing.svg";
import { ReactComponent as DmDevSVG } from "./dm-dev.svg";
import { ReactComponent as DockerSVG } from "./docker.svg";
import { ReactComponent as ElectronSVG } from "./electron.svg";
import { ReactComponent as EmailSVG } from "./email.svg";
import { ReactComponent as ErpSVG } from "./erp.svg";
import { ReactComponent as FeDevSVG } from "./fe-dev.svg";
import { ReactComponent as FirewallSVG } from "./firewall.svg";
import { ReactComponent as FlutterSVG } from "./flutter.svg";
import { ReactComponent as GauntletSVG } from "./gauntlet.svg";
import { ReactComponent as GcpSVG } from "./gcp.svg";
import { ReactComponent as GithubSVG } from "./github.svg";
import { ReactComponent as GoogleCloudSVG } from "./google-cloud.svg";
import { ReactComponent as HamburgerSVG } from "./hamburger.svg";
import { ReactComponent as HomeSVG } from "./home.svg";
import { ReactComponent as HostingSVG } from "./hosting.svg";
import { ReactComponent as InstagramSVG } from "./instagram.svg";
import { ReactComponent as JavaSVG } from "./java.svg";
import { ReactComponent as JsSVG } from "./js.svg";
import { ReactComponent as JupyterSVG } from "./jupyter.svg";
import { ReactComponent as KotlinSVG } from "./kotlin.svg";
import { ReactComponent as KubernetesSVG } from "./kubernetes.svg";
import { ReactComponent as LanguageSVG } from "./language.svg";
import { ReactComponent as ZapSVG } from "./lightning.svg";
import { ReactComponent as LinkedinSVG } from "./linkedin.svg";
import { ReactComponent as LinuxSVG } from "./linux.svg";
import { ReactComponent as MarketSVG } from "./market-icon.svg";
import { ReactComponent as MachineLearningSVG } from "./mechine-learning.svg";
import { ReactComponent as MongoDBSVG } from "./mongodb.svg";
import { ReactComponent as NestJsSVG } from "./nestjs.svg";
import { ReactComponent as NextJsSVG } from "./nextjs.svg";
import { ReactComponent as NginXSVG } from "./nginx.svg";
import { ReactComponent as NlpSVG } from "./nlp.svg";
import { ReactComponent as NodeJsSVG } from "./nodejs.svg";
import { ReactComponent as NumpySVG } from "./numpy.svg";
import { ReactComponent as PandasSVG } from "./pandas.svg";
import { ReactComponent as PhoneSVG } from "./phone.svg";
import { ReactComponent as PinSVG } from "./pin.svg";
import { ReactComponent as PolygonSVG } from "./polygon.svg";
import { ReactComponent as PortfolioSVG } from "./portfolio.svg";
import { ReactComponent as PythonSVG } from "./python.svg";
import { ReactComponent as QuotesSVG } from "./quotes.svg";
import { ReactComponent as ReactSVG } from "./react.svg";
import { ReactComponent as RealTimeSVG } from "./real-time.svg";
import { ReactComponent as RiskSVG } from "./risk.svg";
import { ReactComponent as RobotSVG } from "./robot.svg";
import { ReactComponent as RouterSVG } from "./router.svg";
import { ReactComponent as SeoSVG } from "./seo-icon.svg";
import { ReactComponent as ServicesSVG } from "./services.svg";
import { ReactComponent as SmartContractSVG } from "./smart-contracts.svg";
import { ReactComponent as SoftwareSVG } from "./software.svg";
import { ReactComponent as SolanaSVG } from "./solana.svg";
import { ReactComponent as SoliditySVG } from "./solidity.svg";
import { ReactComponent as SqlSVG } from "./sql.svg";
import { ReactComponent as SupportSVG } from "./support.svg";
import { ReactComponent as SwapSVG } from "./swap.svg";
import { ReactComponent as SwiftSVG } from "./swift.svg";
import { ReactComponent as TelegramSVG } from "./telegram.svg";
import { ReactComponent as TensorflowSVG } from "./tensorflow.svg";
import { ReactComponent as ThreadsSVG } from "./threads.svg";
import { ReactComponent as ThreatDetectionSVG } from "./threat-detection.svg";
import { ReactComponent as ThreatSVG } from "./threat.svg";
import { ReactComponent as TraficSVG } from "./trafic.svg";
import { ReactComponent as TwitterSVG } from "./twitter.svg";
import { ReactComponent as UxDevSVG } from "./ux-dev.svg";
import { ReactComponent as WebDevelopmentSVG } from "./web-development.svg";
import { ReactComponent as WebScannerSVG } from "./web-malware-scanner.svg";
import { ReactComponent as WebSecuritySVG } from "./web-security.svg";
import { ReactComponent as Web3SVG } from "./web3.svg";
import { ReactComponent as WebSocketSVG } from "./websocket.svg";
import { ReactComponent as WireSharkSVG } from "./wireshark.svg";

export const SVGs = {
  GoogleCloudSVG,
  JupyterSVG,
  NumpySVG,
  PandasSVG,
  SqlSVG,
  WebScannerSVG,
  ZapSVG,
  LinuxSVG,
  WireSharkSVG,
  BurpSuiteSVG,
  DappSVG,
  SolanaSVG,
  SmartContractSVG,
  JavaSVG,
  KotlinSVG,
  SwiftSVG,
  FlutterSVG,
  NodeJsSVG,
  NestJsSVG,
  AngularSVG,
  PortfolioSVG,
  ServicesSVG,
  HomeSVG,
  CloseSVG,
  HamburgerSVG,
  SoliditySVG,
  Web3SVG,
  RealTimeSVG,
  RiskSVG,
  SwapSVG,
  RobotSVG,
  ElectronSVG,
  CrossPlatformSVG,
  ChatGPTSVG,
  AiSVG,
  GcpSVG,
  LanguageSVG,
  NlpSVG,
  ChatbotSVG,
  DockerSVG,
  NextJsSVG,
  HostingSVG,
  TelegramSVG,
  WebSocketSVG,
  MongoDBSVG,
  ReactSVG,
  AccessManagementSVG,
  CrmSVG,
  BussinessSVG,
  ApiSVG,
  SoftwareSVG,
  CloudISVG,
  SupportSVG,
  AutomationSVG,
  ErpSVG,
  JsSVG,
  RouterSVG,
  ThreatDetectionSVG,
  ThreatSVG,
  FirewallSVG,
  MachineLearningSVG,
  WebSecuritySVG,
  KubernetesSVG,
  AwsSVG,
  ApacheSparkSVG,
  NginXSVG,
  TensorflowSVG,
  PythonSVG,
  DigitalMarketingSVG,
  CyberSVG,
  DataAiSVG,
  BlockchainSVG,
  TraficSVG,
  ConversionSVG,
  SeoSVG,
  AppDevelopmentSVG,
  MarketSVG,
  CustomSVG,
  PhoneSVG,
  PinSVG,
  EmailSVG,
  WebDevelopmentSVG,
  QuotesSVG,
  InstagramSVG,
  TwitterSVG,
  ThreadsSVG,
  GithubSVG,
  LinkedinSVG,
  GauntletSVG,
  ChainlinkSVG,
  ArbitrumSVG,
  PolygonSVG,
  DmDevSVG,
  FeDevSVG,
  UxDevSVG,
};
