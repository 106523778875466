import React, { useEffect, useRef } from "react";
import { SVGs } from "../../assets/svgs";
import { socials } from "../../utils/data";

const ContactUsForm = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--contact-form-half-height",
        `calc(${height / 2}px + 3rem)`
      );
    }
  }, []);
  return (
    <div
      ref={containerRef}
      className="absolute container px-4 z-50 left-[50%] translate-x-[-50%] translate-y-[-50%] mx-auto flex lg:flex-row flex-col items-stretch justify-center"
    >
      <div className="flex bg-white shadow-dt-service-card flex-col w-[90%] sm:w-full lg:w-[70%] xl:w-3/4 items-start lg:rounded-s-dt-xl lg:rounded-tr-none rounded-t-dt-xl justify-start gap-2 ps-6 pe-8 pt-8 pb-6 mx-auto sm:mx-0">
        <form class="w-full space-y-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                for="email"
                class="block text-dt-section-description font-medium text-dt-dark"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                class="mt-1 block w-full py-2 border-b-[1px] border-b-black px-4"
              />
            </div>
            <div>
              <label
                for="full_name"
                class="block text-dt-section-description font-medium text-dt-dark"
              >
                Full Name
              </label>
              <input
                type="text"
                id="full_name"
                name="full_name"
                required
                class="mt-1 block w-full py-2 border-b-[1px] border-b-black px-4"
              />
            </div>
          </div>

          <div>
            <label
              for="contact"
              class="block text-dt-section-description font-medium text-dt-dark"
            >
              Contact
            </label>
            <input
              type="tel"
              id="contact"
              name="contact"
              required
              class="mt-1 block w-full py-2 border-b-[1px] border-b-black px-4"
            />
          </div>

          <div>
            <label
              for="message"
              class="block text-dt-section-description font-medium text-dt-dark"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              required
              class="mt-1 block w-full py-2 border-b-[1px] border-b-black px-4"
            ></textarea>
          </div>

          <div class="flex justify-start mt-4">
            <button className="bg-dt-main-light border-[2px] rounded-dt-sm border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-light px-[20px] py-[6px] text-dt-nav-link text-white">
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="bg-dt-dark shadow-dt-service-card flex flex-col gap-4 w-[90%] mx-auto sm:mx-0 sm:w-full lg:w-[30%] xl:w-1/4 px-4 py-6 lg:rounded-e-dt-xl lg:rounded-bl-none rounded-b-dt-xl items-start justify-start">
        <p className="text-dt-section-caption font-semibold text-white">
          Contact Info:
        </p>
        <div className="flex flex-col md:flex-row lg:flex-col gap-4 items-start justify-between w-full lg:w-full">
          <div className="flex flex-row gap-2 items-center justify-start w-full md:w-[33%] lg:w-full">
            <SVGs.PhoneSVG className="h-[24px] w-[24px]" />
            <p className="text-dt-section-description text-white text-nowrap">
              +92 331 1449351
            </p>
          </div>
          <div className="flex flex-row gap-2 items-center justify-start w-full md:w-[33%] lg:w-full">
            <SVGs.EmailSVG className="h-[24px] w-[24px]" />
            <p className="text-dt-section-description text-white text-nowrap">
              contact@devtrove.org
            </p>
          </div>
          <div className="flex flex-row gap-2 items-start justify-start  w-full md:w-[33%] lg:w-full">
            <div>
              <SVGs.PinSVG className="h-[24px] w-[24px]" />
            </div>
            <p className="text-dt-section-description text-wrap text-white">
              D20, 4th floor, Crown Tower, G-11/3, Islamabad.
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-4 items-center justify-center w-full lg:mt-auto">
          {socials.map((social, index) => (
            <div key={index} className="social-icon" aria-label={social.alt}>
              <a href={social.link} target="_black" rel="noreferrer">
                {social.icon}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
