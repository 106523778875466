import React from "react";

import { useParams } from "react-router-dom";
import { portfolios } from "../../utils/data";

const PortfolioSubBody = () => {
  const { portfolio } = useParams();

  const content = portfolios.filter((p, _) => p.name === portfolio)[0];
  console.log(content);

  const TechStacks = ({ stacks }) => {
    return (
      <div className="flex items-stretch justify-start gap-6 flex-row flex-wrap">
        {stacks?.map((stack, index) => (
          <div
            key={index}
            className="px-[20px] md:min-w-[124px] min-w-[75px] gap-3 flex items-center justify-center py-[4px] lg:py-[6px] text-[12px] md:text-dt-section-description-sm lg:text-dt-nav-link text-dt-dark capitalize font-medium rounded-dt-sm bg-white shadow-dt-portfolio-card"
          >
            {stack.icon}
            <p className="text-nowrap text-center">{stack.name}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="bg-dt-section-light rounded-t-[33px] z-50 relative">
        <div className="container mx-auto px-4 md:px-8 xl:px-20 py-12 lg:py-16 xl:py-24 flex flex-col gap-12 items-start justify-start">
          <div
            className={`flex flex-col lg:flex-row-reverse items-stretch lg:gap-12 gap-4`}
          >
            <div className="flex flex-col items-start justify-start lg:gap-6 gap-4">
              <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
                Project Overview
              </p>
              <p className="text-dt-dark text-justify text-[12px] md:text-dt-section-description-sm  lg:text-dt-section-description-md xl:text-dt-section-description !leading-[1.35]">
                {content.overview}
              </p>
              <button className="bg-dt-main-light border-[2px] border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-light px-[20px] py-[4px] lg:py-[6px] text-[12px] md:text-dt-section-description-sm lg:text-dt-nav-link text-white capitalize font-medium rounded-dt-sm">
                Contact Us
              </button>
            </div>

            <img
              alt="portfolio-ill"
              src={content.ill}
              className="lg:w-[45%] w-full max-h-[300px] p-2 shadow-dt-portfolio-card bg-white border-solid border-[1px] rounded-dt-md"
            />
          </div>
          <div className="flex flex-col items-start justify-start gap-4">
            <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
              Tools & technologies
            </p>
            <TechStacks stacks={content.technologies} />
          </div>
        </div>
      </div>
      <div className="bg-white z-50 relative">
        <div className="container mx-auto px-4 md:px-8 xl:px-20 py-12 lg:py-16 xl:py-24 flex flex-col gap-4 lg:gap-8 items-start justify-start">
          <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
            Services Provided
          </p>
          <p className="text-dt-dark text-[12px] md:text-dt-section-description-sm  lg:text-dt-section-description-md xl:text-dt-section-description !leading-[1.35]">
            {content.providedServices.text}
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 lg:gap-8 w-full flex-wrap mt-6">
            {content.providedServices.services.map((service, index) => (
              <div
                className="2xl:basis-[20%] xl:basis-[25%] lg:basis-[28%] sm:basis-[30%] w-full flex flex-col px-6 gap-4 bg-white items-center py-16 lg:py-12 xl:py-24 justify-between h-[200px] sm:h-[275px] xl:h-[349px] rounded-[9px] shadow-dt-service-card"
                key={index}
              >
                <div>{service.icon}</div>
                <p className="text-dt-main-blue font-semibold text-center text-dt-section-description-sm md:text-dt-section-description lg:text-dt-nav-link !leading-tight">
                  {service.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-dt-light z-50 relative">
        <div className="container mx-auto px-4 md:px-8 xl:px-20 py-12 lg:py-16 xl:py-24 flex flex-col gap-4 items-stretch justify-start">
          <div
            className={`flex lg:flex-row-reverse flex-col items-start lg:gap-12 gap-4`}
          >
            <div className="flex flex-col items-start justify-start lg:gap-6 gap-4">
              <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
                Challenges
              </p>
              <p className="text-dt-dark text-justify text-[12px] md:text-dt-section-description-sm  lg:text-dt-section-description-md xl:text-dt-section-description !leading-[1.35]">
                {content.challenges}
              </p>
            </div>
            <img
              alt="portfolio-ill"
              src={content.cIll}
              className="lg:w-[45%] shadow-dt-portfolio-card w-full max-h-[300px] object-cover rounded-dt-md"
            />
          </div>
        </div>
      </div>
      <div className="bg-white z-50 relative">
        <div className="container mx-auto px-4 md:px-8 xl:px-20 py-12 lg:py-16 xl:py-24 pb-0 lg:pb-20 flex lg:flex-row flex-col gap-12 items-stretch justify-start">
          <div className="flex flex-col gap-8 lg:w-[65%] w-full">
            <div className="flex flex-col items-start justify-start gap-4">
              <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
                Solutions & Strategy
              </p>
              <p className="text-dt-dark text-justify text-[12px] md:text-dt-section-description-sm  lg:text-dt-section-description-md xl:text-dt-section-description !leading-[1.35]">
                {content.solution}
              </p>
            </div>
            <div className="flex flex-col items-start justify-start gap-4">
              <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
                Results & Impact
              </p>
              <p className="text-dt-dark text-justify text-[12px] md:text-dt-section-description-sm  lg:text-dt-section-description-md xl:text-dt-section-description !leading-[1.35]">
                {content.results}
              </p>
            </div>
          </div>
          <div className=" flex flex-grow flex-col items-center justify-start shadow-dt-service-card rounded-dt-md">
            <div className="w-full py-6 bg-dt-main-blue flex items-center  rounded-t-dt-md justify-center">
              <p className="text-dt-section-description-lg text-white font-bold">
                Contact Us
              </p>
            </div>
            <div className="flex w-full flex-col gap-3 items-start justify-start p-6">
              <div className="w-full">
                <label
                  for="email"
                  class="block text-dt-section-description font-medium text-dt-dark"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  class="mt-1 block w-full py-2 border-b-[1px] border-b-black px-4"
                />
              </div>
              <div className="w-full">
                <label
                  for="contact"
                  class="block text-dt-section-description font-medium text-dt-dark"
                >
                  Contact
                </label>
                <input
                  type="tel"
                  id="contact"
                  name="contact"
                  required
                  class="mt-1 block w-full py-2 border-b-[1px] border-b-black px-4"
                />
              </div>
              <div className="w-full">
                <label
                  for="message"
                  class="block text-dt-section-description font-medium text-dt-dark"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  class="mt-1 block w-full py-2 border-b-[1px] border-b-black px-4"
                ></textarea>
              </div>
              <button className="bg-dt-main-light border-[2px] rounded-dt-sm border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-light px-[20px] py-[6px] text-dt-nav-link text-white">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PortfolioSubBody;
