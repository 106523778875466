import { motion } from "framer-motion";
import React from "react";
import { services } from "../../utils/data";

export const ServiceTags = ({ techs }) => {
  const tags = [...techs, ...techs];

  return (
    <div className="overflow-hidden w-full rounded-dt-md bg-dt-light py-3">
      <motion.div
        className="flex gap-6 items-center whitespace-nowrap"
        animate={{ x: ["0%", "-100%"] }}
        transition={{
          duration: 15,
          ease: "linear",
          repeat: Infinity,
        }}
      >
        {tags.map((tech, index) => (
          <div key={index} className="flex items-center gap-1">
            {tech.icon}
            <p className="text-dt-section-description text-dt-main-blue font-semibold">
              {tech.name}
            </p>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

const ServicesBody = () => {
  return (
    <div className="bg-dt-section-light rounded-t-[33px] z-50 relative">
      <div className="container mx-auto px-4 md:px-8 xl:px-20 py-12 lg:py-16 xl:py-24 flex flex-col gap-12 items-start justify-start">
        {services.map((service, index) => (
          <div
            id={`${service.name.toLowerCase().replaceAll(" ", "-")}`}
            key={index}
            className={`bg-white w-full cursor-pointer rounded-dt-md p-4 md:p-8 lg:p-12 xl:p-16 flex ${
              index % 2 === 0
                ? "lg:flex-row flex-col"
                : "lg:flex-row-reverse flex-col"
            } items-stretch hover:shadow-dt-service-card justify-between`}
          >
            <div className="lg:w-[65%] w-full flex flex-col items-start justify-center gap-4 lg:gap-8">
              <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
                {service.name}
              </p>
              <p className="text-dt-dark text-justify text-[12px] md:text-dt-section-description-sm  lg:text-dt-section-description-md xl:text-dt-section-description !leading-[1.35]">
                {service.overview}
              </p>
              <ServiceTags techs={service.techs} />
            </div>
            <div className="lg:w-[30%] w-full">
              <img
                alt="service-ill"
                src={service.ill}
                className="lg:h-[337px] w-full lg:w-auto rounded-dt-md mt-4 lg:mt-0 object-cover h-[300px]"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesBody;
