import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { portfolios } from "../../utils/data";

const Portfolio = ({ title }) => {
  const navigate = useNavigate();
  const [portfolioToShow, setPortfolioToShow] = useState(4);

  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth < 640) {
        setPortfolioToShow(3);
      } else {
        setPortfolioToShow(4);
      }
    };

    updateCardsToShow();
    window.addEventListener("resize", updateCardsToShow);

    return () => window.removeEventListener("resize", updateCardsToShow);
  }, []);

  return (
    <div className={`${title ? "bg-dt-light" : "bg-white"} relative`}>
      <div className="container mx-auto px-4 md:px-8 xl:px-20 pt-8 py-12 pb-20 flex flex-col items-start justify-start gap-4">
        <div className="flex flex-row items-center justify-between w-full">
          <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
            {title || "Portfolio"}
          </p>
          <button
            onClick={() => navigate("/portfolio")}
            className="bg-dt-main-light border-[2px] border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-light px-[20px] py-[4px] ;g:py-[6px] text-[12px] md:text-dt-section-description-sm lg:text-dt-nav-link text-white capitalize font-medium rounded-dt-sm"
          >
            View More
          </button>
        </div>
        <div className="flex flex-row items-center justify-center sm:gap-x-0 gap-x-4 sm:justify-between flex-wrap w-full gap-y-4">
          {portfolios.slice(0, portfolioToShow).map((portfolio, index) => (
            <div
              onClick={() => navigate(`/portfolio/${portfolio.name}`)}
              className="group flex flex-col rounded-dt-xl cursor-pointer relative shadow-dt-portfolio-card hover:shadow-dt-service-card transition-shadow duration-300 ease-in-out w-full sm:w-[49.25%] h-auto sm:h-[275px] lg:h-[372px] overflow-hidden"
              key={index}
            >
              <img
                alt="portfolio-thumb"
                src={portfolio.ill}
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-110"
              />

              <div className="absolute z-50 top-0 left-0 w-full h-full bg-dt-portfolio-mask opacity-60 group-hover:opacity-100 transition-opacity duration-300 ease-in-out rounded-dt-xl"></div>

              <div className="absolute z-50 bottom-[1rem] lg:bottom-[1.5rem] left-[1.5rem] lg:left-[3rem] flex-col flex gap-2 transition-all duration-300 ease-in-out">
                <p className="text-dt-section-description-lg lg:text-dt-section-caption-lg !leading-none text-white font-semibold">
                  {portfolio.name}
                </p>

                <p className="text-white h-0 group-hover:h-auto text-dt-section-description-sm lg:text-dt-section-description w-4/5 opacity-0 overflow-hidden transition-all duration-300 ease-in-out transform group-hover:opacity-100 group-hover:translate-y-0">
                  {portfolio.oneLiner}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
