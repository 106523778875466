import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderLogo from "../../assets/pngs/header-logo.png";
import { SVGs } from "../../assets/svgs";

const Header = ({ openNav }) => {
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto px-4 md:px-8 xl:px-20">
        <div className="flex flex-row items-center shadow-xl justify-between p-4 rounded-b-dt-md bg-dt-light">
          <img
            onClick={() => navigate("/")}
            alt="logo"
            src={HeaderLogo}
            className="h-[46px] cursor-pointer"
          />
          <div className="lg:flex items-center justify-center gap-12 w-2/5 hidden">
            <Link
              to={"/"}
              className="text-dt-nav-link text-dt-main-blue capitalize tracking-normal hover:font-medium hover:underline transition-all duration-200 ease-out"
            >
              Home
            </Link>
            <Link
              to={"/services"}
              className="text-dt-nav-link text-dt-main-blue capitalize tracking-normal  hover:font-medium hover:underline transition-all duration-200 ease-out"
            >
              Services
            </Link>
            <Link
              to={"/portfolio"}
              className="text-dt-nav-link text-dt-main-blue capitalize tracking-normal  hover:font-medium hover:underline transition-all duration-200 ease-out"
            >
              Portfolio
            </Link>
          </div>
          <button
            onClick={() => navigate("contact-us")}
            className="bg-dt-main-light border-[2px] lg:block hidden border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-light px-[20px] py-[6px] text-dt-nav-link text-white capitalize font-medium rounded-dt-sm"
          >
            contact us
          </button>
          <div className="lg:hidden block">
            <SVGs.HamburgerSVG
              onClick={() => openNav(true)}
              className="h-[46px] w-[46px] cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
