import React from "react";
import ContactUsHero from "../../components/ContactUs/Hero";
import NewLetter from "../../components/ContactUs/NewLetter";
import Footer from "../../components/Layout/Footer";

const ContactUsPage = () => {
  return (
    <div className="z-20 relative">
      <ContactUsHero />
      <NewLetter />
      <Footer />
    </div>
  );
};

export default ContactUsPage;
