import { SVGs } from "../assets/svgs";

import AppDevIll from "../assets/pngs/appdevill.png";
import BcIll from "../assets/pngs/bcill.png";
import CsIll from "../assets/pngs/csill.png";
import DaAiIll from "../assets/pngs/daaisill.png";
import DmIll from "../assets/pngs/dmill.png";
import WebDevIll from "../assets/pngs/webdevill.png";
// portfolios
import ChainLinkIll from "../assets/pngs/chainLInkIll.png";
import FluxVisionIll from "../assets/pngs/fluxVisionIll2.png";
import LexiTalkIll from "../assets/pngs/lexiTalkIll.png";
import MetaTaskerIll from "../assets/pngs/metataskerIll.png";
import OptiFlowERPIll from "../assets/pngs/optiFlowERPIll.png";
import SafeShieldIll from "../assets/pngs/safeShieldIll.png";
import StratifyIll from "../assets/pngs/stratifyIll.png";
import SudoBotIll from "../assets/pngs/sudobotIll.png";

import Cill1 from "../assets/pngs/Cill1.png";
import Cill2 from "../assets/pngs/Cill2.png";
import Cill3 from "../assets/pngs/Cill3.png";
import Cill4 from "../assets/pngs/Cill4.png";
import Cill5 from "../assets/pngs/Cill5.png";
import Cill6 from "../assets/pngs/Cill6.png";
import Cill7 from "../assets/pngs/Cill7.png";

export const services = [
  {
    name: "Web Development",
    icon: (
      <SVGs.WebDevelopmentSVG className="lg:w-[125px] w-[84px] h-[84px] md:w-[64px] md:h-[64px] lg:h-[125px]" />
    ),
    desc: "Building dynamic websites, web portals, and decentralized applications.",
    overview:
      "DevTrove offers advanced website development services, including dynamic websites, web portals, decentralized apps, and custom web solutions. We provide the best products by using HTML5, CSS3, JavaScript (React, Angular), Node.js, Python, PHP, MySQL, MongoDB, WordPress, and Docker for efficient solutions.",
    ill: WebDevIll,
    techs: [
      {
        name: "React",
        icon: <SVGs.ReactSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Angular",
        icon: <SVGs.AngularSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "NestJS",
        icon: <SVGs.NestJsSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "NodeJS",
        icon: <SVGs.NodeJsSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "MongoDB",
        icon: <SVGs.MongoDBSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
    ],
  },
  {
    name: "App Development",
    icon: (
      <SVGs.AppDevelopmentSVG className="lg:w-[125px] w-[84px] h-[84px] md:w-[64px] lg:h-[125px]" />
    ),
    desc: "Creating apps for android, iOS, windows, and hybrid platforms.",
    overview:
      "At DevTrove, we specialize in app development, including interactive android, iOS, windows mobile-compatible, and hybrid apps. We are focused on delivering high quality results that enhance your business's growth and success. We ensure that every solution we provide is effective and tailored to your requirements.",
    ill: AppDevIll,
    techs: [
      {
        name: "React Native",
        icon: <SVGs.ReactSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Flutter",
        icon: <SVGs.FlutterSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Swift",
        icon: <SVGs.SwiftSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Kotlin",
        icon: <SVGs.KotlinSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Java",
        icon: <SVGs.JavaSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
    ],
  },
  {
    name: "Blockchain Solutions",
    icon: (
      <SVGs.BlockchainSVG
        fill="#063085"
        className="lg:w-[125px] w-[84px] h-[84px] md:w-[64px] lg:h-[125px]"
      />
    ),
    desc: "Applying blockchain technology for logistics and other applications.",
    overview:
      "DevTrove's blockchain services transform how businesses operate by making processes more secure and transparent. We offer smart contracts, decentralized apps (dApps), blockchain integration, custom cryptocurrency creation, and improved supply chain management to boost security, efficiency, and trust. We handle Ethereum, smart contracts, Web3.js, IPFS, and ERC tokens.",
    ill: BcIll,
    techs: [
      {
        name: "Solidity",
        icon: <SVGs.SoliditySVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Smart Contracts",
        icon: (
          <SVGs.SmartContractSVG className="w-[24px] h-[24px]" fill="#063085" />
        ),
      },
      {
        name: "Solana",
        icon: <SVGs.SolanaSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "DApp",
        icon: <SVGs.DappSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
    ],
  },
  {
    name: "Cyber Security Solutions",
    icon: (
      <SVGs.CyberSVG className="lg:w-[125px] w-[84px] h-[84px] md:w-[64px] lg:h-[125px]" />
    ),
    desc: "Conducting security tests, audits, and protection for websites.",
    overview:
      "At DevTrove, our Cyber Security Solutions offer robust protection with comprehensive services such as penetration testing, detailed website audits, and in-depth attack surface analysis. We enhance website security and protection, implementing preventive strategies to defend against cyber threats and safeguard your digital assets effectively.",
    ill: CsIll,
    techs: [
      {
        name: "Burp Suite",
        icon: (
          <SVGs.BurpSuiteSVG className="w-[24px] h-[24px]" fill="#063085" />
        ),
      },
      {
        name: "Wireshark",
        icon: (
          <SVGs.WireSharkSVG className="w-[24px] h-[24px]" fill="#063085" />
        ),
      },
      {
        name: "Nikto",
        icon: (
          <SVGs.WebScannerSVG className="w-[24px] h-[24px]" fill="#063085" />
        ),
      },
      {
        name: "OWASP ZAP",
        icon: <SVGs.ZapSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "SQLMap",
        icon: <SVGs.SqlSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
    ],
  },
  {
    name: "Data & AI Solutions",
    icon: (
      <SVGs.DataAiSVG
        fill="#063085"
        className="lg:w-[125px] w-[84px] h-[84px] md:w-[64px] lg:h-[125px]"
      />
    ),
    desc: "Providing AI chatbots, data analysis, and machine learning services.",
    overview:
      "Our Data & AI solutions will enhance your business with AI chatbots, machine learning, and effective data strategies. We address issues like data fragmentation and inefficiency, helping you streamline, analyze, and fully leverage your data for better insights and a competitive advantage. We ensure that your data drives smarter decisions and fuels growth with our tailored approach.",
    ill: DaAiIll,
    techs: [
      {
        name: "Python",
        icon: <SVGs.PythonSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Pandas",
        icon: <SVGs.PandasSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "NumPy",
        icon: <SVGs.NumpySVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Jupyter Notebook",
        icon: <SVGs.JupyterSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Google Cloud AI",
        icon: (
          <SVGs.GoogleCloudSVG className="w-[24px] h-[24px]" fill="#063085" />
        ),
      },
    ],
  },
  {
    name: "Digital Marketing",
    icon: (
      <SVGs.DigitalMarketingSVG
        fill="#063085"
        className="lg:w-[125px] w-[84px] h-[84px] md:w-[64px] lg:h-[125px]"
      />
    ),
    desc: "Enhancing visibility through brand identity, SEO, email marketing, and blogs.",
    overview:
      "At DevTrove, we believe great products need effective marketing to shine. Our Digital Marketing services, including SEO, email marketing, search engine marketing, and done-for-you blogs, provide a goal-driven approach to boost your visibility and take your products to new heights. Our team works hard to create strategies that drive real results.",
    ill: DmIll,
    techs: [
      {
        name: "React",
        icon: <SVGs.ReactSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "Angular",
        icon: <SVGs.AngularSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "NestJS",
        icon: <SVGs.NestJsSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "NodeJS",
        icon: <SVGs.NodeJsSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
      {
        name: "MongoDB",
        icon: <SVGs.MongoDBSVG className="w-[24px] h-[24px]" fill="#063085" />,
      },
    ],
  },
];

export const portfolios = [
  {
    name: "SafeSheild",
    ill: SafeShieldIll,
    cIll: Cill1,
    oneLiner:
      "SafeShield uses machine learning to upgrade ModSecurity, improving real-time threat detection and defense against web attacks.",
    desc: "SafeShield enhances web security with its Machine Learning-Enhanced Web Application Firewall (ML-WAF), which utilizes machine learning for improved threat detection and response against various web attacks in real time. It also offers a Chrome extension and desktop application, delivering robust protection and peace of mind with its advanced security solutions.",
    overview:
      "SafeShield enhances web security with its Machine Learning-Enhanced Web Application Firewall (ML-WAF), which utilizes machine learning for improved threat detection and response against various web attacks in real time. It also offers a Chrome extension and desktop application, delivering robust protection and peace of mind with its advanced security solutions.",
    providedServices: {
      text: "DevTrove offered numerous services to develop and launch SafeShield, including:",
      services: [
        {
          name: "Web Security Architecture",
          icon: (
            <SVGs.WebSecuritySVG
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
              fill="#063085"
            />
          ),
        },
        {
          name: "Machine Learning Integration",
          icon: (
            <SVGs.MachineLearningSVG
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
              fill="#063085"
            />
          ),
        },
        {
          name: "Firewall Customization",
          icon: (
            <SVGs.FirewallSVG
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
              fill="#063085"
            />
          ),
        },
        {
          name: "Threat Detection & Response Optimization",
          icon: (
            <SVGs.ThreatDetectionSVG
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
              fill="#063085"
            />
          ),
        },
      ],
    },
    technologies: [
      { name: "Python", icon: <SVGs.PythonSVG height={24} width={24} /> },
      {
        name: "TensorFlow",
        icon: <SVGs.TensorflowSVG height={24} width={24} />,
      },
      { name: "Nginx", icon: <SVGs.NginXSVG height={24} width={24} /> },
      {
        name: "Apache Spark",
        icon: <SVGs.ApacheSparkSVG height={24} width={24} />,
      },
      { name: "AWS", icon: <SVGs.AwsSVG height={24} width={24} /> },
      {
        name: "Kubernetes",
        icon: <SVGs.KubernetesSVG height={24} width={24} />,
      },
    ],
    challenges:
      "Developing SafeShield came with several challenges, primarily focused on enhancing the speed and accuracy of threat detection without compromising system performance. Handling large volumes of web traffic while identifying evolving attack patterns, such as zero-day threats, required complex machine learning models. Additionally, balancing real-time threat responses with minimal impact on user experience was a key priority.",
    solution:
      "To address these challenges, we implemented an optimized machine learning pipeline capable of analyzing real-time web traffic while detecting subtle threat patterns. SafeShield’s system architecture was built for scalability, allowing it to handle high-traffic volumes seamlessly. By incorporating behavior-based detection mechanisms, we enhanced its ability to identify sophisticated and emerging threats. Continuous user feedback and rigorous testing allowed for iterative improvements, making SafeShield more reliable and efficient with each update.",
    results:
      "Following its deployment, SafeShield demonstrated remarkable improvements in web security, reducing vulnerability risks by up to 75%. Its ML-WAF successfully blocked an average of 96% of malicious web requests, including SQL injections, cross-site scripting, and DDoS attacks. Clients reported a significant decrease in security incidents, with enhanced response times that contributed to the seamless operation of their web platforms. The adaptive nature of SafeShield led to widespread adoption across various industries, proving it to be an essential asset in fortifying web infrastructures against modern-day cyber threats.",
  },
  {
    name: "Chainlytics",
    ill: ChainLinkIll,
    cIll: Cill2,
    oneLiner:
      "Chainlytics is a blockchain analytics platform providing real-time insights for crypto investors.",
    desc: "Chainlytics is a blockchain analytics platform providing real-time insights for crypto investors. It features dashboards like Spotlight, NFT Paradise, Token God Mode, and Smart Money to track on-chain activities, wallets, and tokens. With support for DeFi analytics, smart alerts, and NFT tracking for informed decision-making based on wallet behaviors and trends across blockchain networks.",
    overview:
      "Chainlytics is an advanced blockchain analytics platform designed to provide real-time insights and actionable intelligence for cryptocurrency investors. It offers detailed dashboards, including Spotlight, NFT Paradise, Token God Mode, and Smart Money, to track on-chain activities, wallet profiles, and token movements. Additionally, Chainlytics offers a comprehensive toolkit for navigating the complex blockchain ecosystem. It includes features such as DeFi analytics, wallet and token profilers, smart alerts, and NFT data tracking for informed decision-making based on wallet behaviors and trends across blockchain networks.",
    providedServices: {
      text: "We offered a range of services for Chainlytics, including:",
      services: [
        {
          name: "Website Development",
          icon: (
            <SVGs.WebDevelopmentSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
          ),
        },
        {
          name: "DApp Development",
          icon: (
            <SVGs.AppDevelopmentSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
          ),
        },
        {
          name: "Data Analytics Solutions",
          icon: (
            <SVGs.DataAiSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "User Experience Design",
          icon: (
            <SVGs.UxDevSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
          ),
        },
        {
          name: "Ongoing Support and Optimization",
          icon: (
            <SVGs.TensorflowSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
      ],
    },
    technologies: [
      {
        name: "Blockchain Technology",
        icon: <SVGs.BlockchainSVG height={24} width={24} />,
      },
      {
        name: "Data Visualization Tools",
        icon: <SVGs.DataAiSVG height={24} width={24} />,
      },
      { name: "Python", icon: <SVGs.PythonSVG height={24} width={24} /> },
      { name: "Router", icon: <SVGs.RouterSVG height={24} width={24} /> },
      { name: "MERN Stack", icon: <SVGs.JsSVG height={24} width={24} /> },
    ],
    challenges:
      "The development of Chainlytics presented challenges related to integrating diverse data sources and ensuring the accuracy of real-time analytics. Additionally, creating an intuitive user interface that effectively presents complex data in a digestible format was crucial for user engagement and satisfaction.",
    solution:
      "To address these challenges, we implemented a data-driven approach that enabled a seamless integration of on-chain data across multiple blockchain networks. Moreover, we utilized advanced algorithms and machine learning to ensure the platform delivers accurate, real-time analytics tailored to user needs. Additionally, our design team focused on creating user-friendly dashboards that visually represent complex analytics, making it easier for users to interpret data and gain insights.",
    results:
      "The launch of Chainlytics has significantly enhanced the decision-making capabilities of cryptocurrency investors by providing them with real-time data and insights. Users benefit from a deeper understanding of wallet behaviors, transaction patterns, and market trends across various blockchain networks. The comprehensive features and intuitive design have fostered high user engagement and satisfaction, positioning Chainlytics as an essential tool for navigating the evolving landscape of blockchain and cryptocurrency investments.",
  },
  {
    name: "OptiFlow ERP",
    ill: OptiFlowERPIll,
    cIll: Cill3,
    oneLiner:
      "It offers features like financial management, CRM, project tracking, and inventory control to grow your business.",
    desc: "This integrated platform centralizes key business processes across finance, HR, supply chain, and manufacturing. By automating workflows and providing real-time data insights, the ERP system enhances efficiency, collaboration, and decision-making. It offers features like financial management, CRM, project tracking, and inventory control to grow your business.",
    overview:
      "This integrated platform centralizes key business processes across finance, HR, supply chain, manufacturing, and more. By automating workflows and providing real-time data insights, it significantly improves efficiency and enhances collaboration among teams. Moreover, it’s designed to support better decision-making. It offers features like financial management, CRM, project tracking, and inventory control, making it a scalable solution that grows alongside your business.",
    providedServices: {
      text: "DevTrove offered a range of services to bring this platform to life, including:",
      services: [
        {
          name: "Tailored ERP System Development",
          icon: (
            <SVGs.ErpSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Workflow Automation Implementation",
          icon: (
            <SVGs.AutomationSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "User-Centric Design",
          icon: (
            <SVGs.UxDevSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
          ),
        },
        {
          name: "Ongoing Support and Optimization",
          icon: (
            <SVGs.SupportSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
      ],
    },
    technologies: [
      {
        name: "Cloud Infrastructure",
        icon: <SVGs.CloudISVG height={24} width={24} />,
      },
      {
        name: "Advanced Data Analytics",
        icon: <SVGs.DataAiSVG height={24} width={24} />,
      },
      {
        name: "Custom Software Development",
        icon: <SVGs.SoftwareSVG height={24} width={24} />,
      },
      {
        name: "API Integrations",
        icon: <SVGs.ApiSVG height={24} width={24} />,
      },
      {
        name: "Business Intelligence Solutions",
        icon: <SVGs.BussinessSVG height={24} width={24} />,
      },
    ],
    challenges:
      "During the development process, we faced the challenge of smoothly integrating different business functions. It was essential to make sure that the system not only streamlined operations but also met the specific needs of each department without compromising data integrity.",
    solution:
      "Our approach was to build a flexible and modular system that allows different business units to operate independently while remaining interconnected. This design ensures that users can customize their workflows to fit their specific needs. Our data analytics help users make better decisions and plan more effectively by providing practical insights.",
    results:
      "Since implementing this OptiFlow ERP system, our clients' businesses have experienced a significant increase in operational efficiency and productivity. By automating routine tasks and providing real-time insights, teams now have the freedom to concentrate on what truly matters, driving growth and innovation. With its adaptable design, this platform grows with your business, ensuring you can adjust to new challenges and take advantage of opportunities as they arise. Overall, it’s more than just a system; it’s a game-changer for organizations looking to succeed in a competitive environment.",
  },
  {
    name: "Stratify",
    ill: StratifyIll,
    cIll: Cill4,
    desc: "This trading platform supports Forex, Crypto, Stocks, Commodities, and Indices. It features real-time market data, secure payment gateways, multi-wallet management, and a role-based CRM for management oversight. It also includes a Telegram Bot for automated investments, offering a seamless trading experience.",
    oneLiner:
      "This trading platform supports Forex, Crypto, Stocks, Commodities, and Indices.",
    overview:
      "Stratify is a comprehensive trading platform for a wide range of users, from end users to higher management. The platform features a robust Trading Application that supports multiple financial markets, including Forex, Crypto, Stocks, Commodities, and Indices. By integrating real-time market data, secure payment gateways, and multi-wallet management with support for various blockchain networks, Stratify offers a seamless trading experience.",
    providedServices: {
      text: "DevTrove offered a diverse suite of services to develop and launch Stratify, including:",
      services: [
        {
          name: "Trading Application Development",
          icon: (
            <SVGs.AppDevelopmentSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "CRM System Integration",
          icon: (
            <SVGs.CrmSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Role-Based Access Management",
          icon: (
            <SVGs.AccessManagementSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Automated Notification and Investment Functionality",
          icon: (
            <SVGs.AutomationSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
      ],
    },
    technologies: [
      {
        name: "JavaScript (Node.js)",
        icon: <SVGs.JsSVG height={24} width={24} />,
      },
      { name: "React Native", icon: <SVGs.ReactSVG height={24} width={24} /> },
      { name: "MongoDB", icon: <SVGs.MongoDBSVG height={24} width={24} /> },
      {
        name: "WebSockets",
        icon: <SVGs.WebSocketSVG height={24} width={24} />,
      },
    ],
    challenges:
      "Developing Stratify posed several challenges, particularly in ensuring the platform's robustness across multiple financial markets. Integrating real-time market data while maintaining performance stability required sophisticated backend architecture. Additionally, implementing a secure payment gateway and multi-wallet management demanded careful consideration of compliance and security protocols. The CRM system's role-based access required careful planning to manage user privileges without compromising security.",
    solution:
      "To tackle these challenges, we designed a scalable architecture that could support the demands of real-time data processing across various financial markets. By utilizing WebSocket technology, we ensured that users received instant market updates, enhancing their trading experience. Secure payment gateways were integrated with best practices to ensure compliance and protect user data. The CRM system was built with flexibility in mind, allowing for easy management of user access and strategies, thereby streamlining operations for higher management.",
    results:
      "Since its launch, Stratify has proven to be an invaluable tool for traders and managers. Users have reported improved operational efficiency and enhanced trading outcomes due to the platform's real-time data capabilities and user-friendly design. The CRM system has empowered management to make informed decisions based on comprehensive trade oversight, leading to a significant increase in user satisfaction. The integration of automated investment features has also resulted in higher engagement levels among users, making it a preferred trading platform in the market.",
  },
  {
    name: "SudoBot",
    ill: SudoBotIll,
    cIll: Cill5,
    desc: "This Telegram bot serves as a one-stop hub for managing hosting, domains, and future node services. It offers user-friendly functionalities such as purchasing and managing hosting packages and domain registration. Soon, it will also offer node and website creation, all through an intuitive interface.",
    oneLiner:
      "This Telegram bot serves as a one-stop hub for managing hosting, domains, and future node services.",
    overview:
      "SudoBot is a Telegram-based bot designed to simplify the management of hosting, domains, containerization, and node development services. It offers user-friendly functionalities such as purchasing and managing hosting packages and domain registration. Soon, it will also offer node and website creation, all through an intuitive interface with simple prompts. SudoBot empowers users to streamline their cloud management and AI needs with ease and confidence.",
    providedServices: {
      text: "Dev Trove provided a comprehensive suite of services to develop and deploy SudoBot, including:",
      services: [
        {
          name: "Telegram Bot Development",
          icon: (
            <SVGs.TelegramSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "UI/UX Design",
          icon: (
            <SVGs.UxDevSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
          ),
        },
        {
          name: "Hosting and Domain Management",
          icon: (
            <SVGs.HostingSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Automation of User Notifications and Updates",
          icon: (
            <SVGs.AutomationSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Ongoing Support and Maintenance",
          icon: (
            <SVGs.SupportSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Social Media Marketing",
          icon: (
            <SVGs.DigitalMarketingSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
      ],
    },
    technologies: [
      {
        name: "Telegram Bot API",
        icon: <SVGs.TelegramSVG height={24} width={24} />,
      },
      {
        name: "Node.js",
        icon: <SVGs.JsSVG height={24} width={24} />,
      },
      {
        name: "Next Js",
        icon: <SVGs.NextJsSVG height={24} width={24} />,
      },
      {
        name: "AWS",
        icon: <SVGs.AwsSVG height={24} width={24} />,
      },
      {
        name: "Docker",
        icon: <SVGs.DockerSVG height={24} width={24} />,
      },
      {
        name: "RESTful APIs",
        icon: <SVGs.ApiSVG height={24} width={24} />,
      },
    ],
    challenges:
      "While developing SudoBot, we faced challenges in creating a responsive and engaging user experience within the Telegram platform. Additionally, we encountered difficulties in consolidating various services into one bot, including hosting, RDP, domain, and more.",
    solution:
      "To address these challenges, we designed a highly interactive and intuitive bot that simplifies user interactions. By leveraging the Telegram API, we integrated essential features for managing hosting and domains, ensuring that users could navigate the interface effortlessly.",
    results:
      "The launch of SudoBot has transformed how users manage their hosting and domain needs. Its user-friendly functionalities allow customers to handle their online services confidently and efficiently. As we move toward future phases that include node and website creation, SudoBot is set to become an indispensable tool for individuals and businesses looking to simplify their digital management tasks.",
  },
  {
    name: "LexiTalk",
    ill: LexiTalkIll,
    cIll: Cill6,
    desc: "This project develops chatbots powered by Large Language Models (LLMs) for sectors like legal services, healthcare, and immigration. Trained on localized data, they provide region-specific responses and features such as multi-lingual support and continuous learning. Ideal for governments and businesses, these bots offer accurate, real-time information for efficient solutions.",
    oneLiner:
      "Ideal for governments and businesses, these bots offers accurate, real-time information for efficient solutions.",
    overview:
      "LexiTalk is a chatbot solution powered by Large Language Models (LLMs), designed for industries such as legal services, healthcare, and immigration. These chatbots are trained on localized data to ensure region-specific, compliant responses. They include features such as multi-lingual support and continuous learning. LexiTalk delivers accurate, real-time information, making it an ideal solution for governments and businesses seeking efficient, user-friendly customer service tools.",
    providedServices: {
      text: "DevTrove provided a comprehensive suite of services to develop and deploy LexiTalk, including:",
      services: [
        {
          name: "Chatbot Development",
          icon: (
            <SVGs.ChatbotSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Natural Language Processing (NLP) Integration",
          icon: (
            <SVGs.NlpSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Multi-Lingual Support",
          icon: (
            <SVGs.LanguageSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Continuous Learning & Data Training",
          icon: (
            <SVGs.DataAiSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
      ],
    },
    technologies: [
      { name: "MERN Stack", icon: <SVGs.JsSVG height={24} width={24} /> },
      {
        name: "TensorFlow",
        icon: <SVGs.TensorflowSVG height={24} width={24} />,
      },
      {
        name: "Google Cloud Platform (GCP)",
        icon: <SVGs.GcpSVG height={24} width={24} />,
      },
      { name: "Docker ", icon: <SVGs.DockerSVG height={24} width={24} /> },
    ],
    challenges:
      "Developing LexiTalk posed several challenges, particularly in achieving high accuracy for command and voice recognition. Interpreting natural language complexities like ambiguities, slang, and idioms was also a hurdle. Ensuring real-time responses while handling various command complexities and integrating multi-lingual capabilities required significant optimization. Compliance with local regulations for legal and healthcare industries further added to the complexity.",
    solution:
      "To overcome these challenges, we built advanced natural language processing models using localized data to improve the accuracy of chatbot responses. Real-time processing was enhanced through efficient algorithms, and multi-lingual support was integrated to cater to diverse users. Continuous learning was implemented, allowing the chatbots to adapt and evolve with changing regulations and data. Rigorous testing and user feedback ensured LexiTalk’s reliability across different sectors.",
    results:
      "After deployment, LexiTalk delivered exceptional results, with clients reporting up to a 40% improvement in query resolution times across legal services and a 35% increase in user satisfaction in healthcare. The chatbots’ multi-lingual capabilities widened their usability, while real-time responses boosted efficiency. LexiTalk’s continuous learning feature ensures it stays up-to-date with evolving data and regulations, making it a reliable and adaptive solution for businesses and governments.",
  },
  {
    name: "MetaTasker",
    ill: MetaTaskerIll,
    cIll: Cill6,
    desc: "MetaTasker is a Web3 productivity platform that enhances task management using AI models like GPT-4v and BERT. With a ChatGPT-like interface, it offers cross-platform functionality, task automation via text and voice inputs, and crypto trading integration. Users can multitask, schedule tasks, and manage operations seamlessly to boost productivity.",
    oneLiner:
      "MetaTasker is a Web3 productivity platform that enhances task management using AI models like GPT-4v and BERT.",
    overview:
      "MetaTasker is an innovative Web3 productivity platform that enhances task management through advanced AI models, including GPT-4v and BERT. With a ChatGPT-like interface, MetaTasker integrates cross-platform functionality and supports task automation via both text and voice inputs. MetaTasker allows users to efficiently multitask, save chats, execute tasks in real-time, and schedule future tasks. Additionally, it offers features like crypto trading automation, wallet integration, and self-operating computer capabilities, positioning MetaTasker as a forward-thinking solution for digital productivity. The sleek, modern design ensures users can monitor, terminate, or regenerate tasks effortlessly.",
    providedServices: {
      text: "DevTrove offered a comprehensive suite of services to develop and launch MetaTasker, including:",
      services: [
        {
          name: "AI Model Integration",
          icon: (
            <SVGs.AiSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Cross-Platform Functionality",
          icon: (
            <SVGs.CrossPlatformSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Task Automation Development",
          icon: (
            <SVGs.AutomationSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "User Interface Design",
          icon: (
            <SVGs.UxDevSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
          ),
        },
      ],
    },
    technologies: [
      {
        name: "MERN Stack",
        icon: <SVGs.JsSVG height={24} width={24} />,
      },
      {
        name: "GPT-4v",
        icon: <SVGs.ChatGPTSVG height={24} width={24} />,
      },
      {
        name: "BERT",
        icon: <SVGs.AiSVG height={24} width={24} />,
      },
      {
        name: "Node.js",
        icon: <SVGs.JsSVG height={24} width={24} />,
      },
    ],
    challenges:
      "The development of MetaTasker faced several challenges, particularly in integrating advanced AI models while ensuring seamless performance. Achieving real-time task execution and automation required optimizing algorithms for both text and voice inputs. Additionally, the complexity of managing crypto trading automation and wallet integration posed significant hurdles, along with ensuring a user-friendly experience within the sleek design.",
    solution:
      "To solve these challenges, we implemented advanced AI models, optimizing their integration for efficient task automation. Real-time execution was achieved through effective algorithms, allowing users to manage tasks seamlessly via text and voice. We focused on creating a modern, intuitive interface that enhances user experience while providing robust features like crypto trading automation and wallet integration. Finally, we have achieved a reliable platform through continuous testing and user feedback.",
    results:
      "After its launch, MetaTasker demonstrated remarkable improvements in digital productivity, enabling users to manage tasks more efficiently than ever. The platform’s automation features led to a significant reduction in task completion times, with users reporting a 50% increase in productivity. The ChatGPT-like interface made it easy to use, and real-time features kept users engaged. MetaTasker is gaining popularity as a modern solution for organized task management in today's digital world.",
  },
  {
    name: "FluxVision",
    ill: FluxVisionIll,
    cIll: Cill7,
    desc: "FluxVision desktop application is a cryptocurrency trading platform for Windows and Mac that allows users to buy and sell digital currencies with real-time charting and data from Dexscreener, Dextools, and TradingView. It uses ChatGPT for voice and text commands and Robot Framework for trading automation, along with Twitter integration for crypto news updates.",
    oneLiner:
      "FluxVision desktop application is a cryptocurrency trading platform for Windows and Mac.",
    overview:
      "The FluxVision desktop application is a cryptocurrency trading platform designed for both Windows and Mac users. This powerful application enables users to buy and sell digital currencies while providing real-time charting and data integration from popular platforms such as Dexscreener, Dextools, and TradingView. It utilizes ChatGPT to process and execute voice and text commands, simplifying user interactions through advanced natural language processing. The application also employs Robot Framework for automation, allowing users to trade and view charts effortlessly.",
    providedServices: {
      text: "DevTrove offered a comprehensive suite of services to develop and launch FluxVision, including:",
      services: [
        {
          name: "Cryptocurrency Trading Functionality",
          icon: (
            <SVGs.BlockchainSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Real-Time Data Integration",
          icon: (
            <SVGs.DataAiSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "Voice and Text Command Processing",
          icon: (
            <SVGs.SupportSVG
              fill="#063085"
              className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]"
            />
          ),
        },
        {
          name: "User Interface Development",
          icon: (
            <SVGs.UxDevSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
          ),
        },
      ],
    },
    technologies: [
      { name: "Electron", icon: <SVGs.ElectronSVG height={24} width={24} /> },
      { name: "ChatGPT", icon: <SVGs.ChatGPTSVG height={24} width={24} /> },
      {
        name: "Robot Framework",
        icon: <SVGs.RobotSVG height={24} width={24} />,
      },
      { name: "TradingView API", icon: <SVGs.ApiSVG height={24} width={24} /> },
    ],
    challenges:
      "Developing FluxVision presented several challenges, primarily in ensuring real-time data integration from multiple sources while maintaining performance stability. The complexity of processing natural language commands for both voice and text inputs required sophisticated algorithms and optimization. Additionally, automating trading processes through Robot Framework involved intricate coding to ensure accurate execution and error handling. Ensuring seamless integration with Twitter for live updates and user interaction added further complexity to the development process.",
    solution:
      "To address these challenges, we implemented an efficient architecture that facilitates real-time data streaming from multiple platforms while optimizing performance. Advanced natural language processing algorithms were developed to ensure accurate interpretation of voice and text commands. The automation process was streamlined using Robot Framework, allowing users to execute trades and view charts with minimal effort. The integration with Twitter was designed to provide users with timely updates and easy access to influential accounts, enhancing the overall trading experience.",
    results:
      "Upon its launch, FluxVision emerged as a powerful tool for cryptocurrency traders, offering a seamless trading experience with a 40% increase in efficiency for users managing multiple trades. The real-time charting features and integrated data sources provided traders with valuable insights, resulting in more informed decision-making. Users reported a significant improvement in their ability to react to market changes quickly, thanks to the natural language processing capabilities that simplified interactions. FluxVision is becoming the preferred platform for traders seeking to enhance their cryptocurrency trading experience.",
  },
];

export const testemonials = [
  {
    name: "Rafiyal Rahim",
    text: "The website offers an excellent user experience with top-quality products. Customer service is responsive and efficient.",
    translateX: 50,
    translateY: -75,
    opacity: 0,
  },
  {
    name: "Jame Araujo",
    text: "I am really impressed with the website's user-friendly design and quality products. Their customer support has always been helpful.",
    translateX: 25,
    translateY: 0,
    opacity: 0.5,
  },
  {
    name: "Rose Acuna",
    text: "I always choose DevTrove for its outstanding quality and efficiency. I highly recommend their services to anyone seeking solid solutions.",
    translateX: 0,
    translateY: 75,
    opacity: 1,
  },
  {
    name: "Fernanda Jaramillo",
    text: "DevTrove provides the best solutions for all your problems, it offers unmatched quality and reliability in every project.",
    translateX: 25,
    translateY: 150,
    opacity: 0.5,
  },
  {
    name: "Siyan Miah",
    text: "DevTrove excels at delivering solutions altered to your needs. Their expertise and dedication ensure the best outcomes.",
    translateX: 50,
    translateY: 225,
    opacity: 0,
  },
];

export const testemonialsStats = [
  { name: "Client Satisfaction", value: "97%" },
  { name: "Client Retention", value: "85%" },
  { name: "Completed Projects", value: "250+" },
];

export const socials = [
  { icon: <SVGs.TwitterSVG />, alt: "X", link: "http://x.com/dev_trove" },
  {
    icon: <SVGs.InstagramSVG />,
    alt: "Instagram",
    link: "http://instagram.com/dev_trove",
  },
  {
    icon: <SVGs.GithubSVG />,
    alt: "Github",
    link: "http://github.com/dev-trove",
  },
  {
    icon: <SVGs.ThreadsSVG />,
    alt: "Thread",
    link: "http://threads.net/@dev_trove",
  },
  {
    icon: <SVGs.LinkedinSVG />,
    alt: "LinkedIn",
    link: "http://linkedin.com/company/devtrove/",
  },
];

export const techStacks = ["ReactJS", "NodeJS", "MongoDB", "NestJS"];

export const providedServices = [
  {
    icon: (
      <SVGs.DmDevSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
    ),
    name: "Frontend Development",
  },
  {
    icon: (
      <SVGs.UxDevSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
    ),
    name: "UI/UX Design",
  },
  {
    icon: (
      <SVGs.DmDevSVG className="lg:w-[75px] w-[36px] h-[36px] md:w-[50px] md:h-[50px] lg:h-[75px]" />
    ),
    name: "Digital Marketing Strategy",
  },
];

export const benefits = [
  { icon: <SVGs.TraficSVG />, name: "Increased Trafic" },
  { icon: <SVGs.UxDevSVG />, name: "Better UX" },
  { icon: <SVGs.ConversionSVG />, name: "High Conversion Rates" },
];

export const challenges = [
  { icon: <SVGs.MarketSVG />, name: "Custom Website Design" },
  { icon: <SVGs.CustomSVG />, name: "E-commerce Integration" },
];

export const keyFeatures = [
  { icon: <SVGs.MarketSVG />, name: "Custom Website Design" },
  { icon: <SVGs.CustomSVG />, name: "E-commerce Integration" },
  { icon: <SVGs.SeoSVG />, name: "SEO Optimization" },
];

export const footerLinks = [
  { name: "Home", link: "/" },
  { name: "Services", link: "/services" },
  { name: "Portfolio", link: "/portfolio" },
  { name: "Contact Us", link: "/contact-us" },
];
