import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { services } from "../../utils/data";

const Services = () => {
  const navigate = useNavigate();
  const [servicesToShow, setServicesToShow] = useState(6);

  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth < 640) {
        setServicesToShow(3);
      } else {
        setServicesToShow(6);
      }
    };

    updateCardsToShow();
    window.addEventListener("resize", updateCardsToShow);

    return () => window.removeEventListener("resize", updateCardsToShow);
  }, []);

  return (
    <div className="bg-dt-section-light rounded-t-[33px] z-50 relative">
      <div className="container mx-auto px-4 md:px-8 xl:px-20 pt-8 lg:pt-12 pb-20 flex flex-col gap-3 items-start justify-start">
        <div className="flex items-center justify-between w-full">
          <p className="text-dt-section-description-sm md:text-dt-section-description lg:text-dt-section-caption text-dt-blue-light !leading-[1.24]">
            What We Offer
          </p>
          <button
            onClick={() => navigate("/services")}
            className="bg-dt-main-light border-[2px] border-dt-main-light hover:text-dt-main-light transition-all duration-300 ease-in-out hover:border-dt-main-light hover:bg-dt-light px-[20px] py-[4px] ;g:py-[6px] text-[12px] md:text-dt-section-description-sm lg:text-dt-nav-link text-white capitalize font-medium rounded-dt-sm"
          >
            View All
          </button>
        </div>
        <p className="text-dt-main-blue font-bold text-dt-section-caption-md md:text-dt-section-caption-lg lg:text-dt-section-heading !leading-[1.1]">
          Get solutions that
          <br />
          Improve your ROI
        </p>
        <p className="text-dt-dark text-[12px] md:text-dt-section-description-sm  lg:text-dt-section-description-md xl:text-dt-section-description !leading-[1.35]">
          Our expert team works with the latest technology 24/7 to meet your
          needs.
          <br />
          We deliver matchless results that grow and elevate your business with
          dedication and commitment.
        </p>
        <div className="flex flex-row mt-4 items-center justify-center gap-6 flex-wrap">
          {services.slice(0, servicesToShow).map((service, index) => (
            <motion.div
              className="sm:basis-[48%] basis-[100%] md:basis-[31%] xl:basis-[25%] relative cursor-pointer flex flex-col gap-2 bg-white px-6 overflow-hidden items-center justify-center h-[200px] sm:h-[275px] lg:h-[350px] xl:h-[399px] rounded-[9px] shadow-dt-service-card"
              key={index}
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
            >
              {/* Service icon and title */}
              <div className="flex flex-col items-center justify-center z-10">
                {service.icon}
                <p className="text-dt-main-blue font-semibold text-center text-dt-section-description-sm md:text-dt-section-description lg:text-dt-section-caption !leading-tight">
                  {service.name}
                </p>
              </div>

              {/* Hover overlay with description and button */}
              <motion.div
                className="absolute inset-0 bg-dt-main-blue flex flex-col items-center justify-center z-20"
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <p className="text-white text-dt-section-description-sm md:text-dt-section-description w-3/4 text-center">
                  {service.desc}
                </p>
                <motion.button
                  onClick={() =>
                    navigate(
                      `/services#${service.name
                        .toLowerCase()
                        .replaceAll(" ", "-")}`
                    )
                  }
                  className="bg-white px-[22px] py-[8px] shadow-dt-input-card text-[12px] md:text-dt-section-description-sm text-dt-main-blue capitalize rounded-dt-sm mt-4"
                  whileHover={{ scale: 1.05 }}
                >
                  Read More
                </motion.button>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
